<template>
  <div style="max-width:100%; width: 100%;height:100%;overflow-x: auto;" class="d-flex flex-column py-3">
    <LeftTopPanelTitle
      :loading="loading"
      text="My Club"
      icon="medal"
    />
    <ul class="nav nav-tabs d-flex justify-content-around my-3 font-weight-bold">
      <li
        v-for="(page, i) in pages"
        :key="i"
        class="nav-item text-center text-nowrap flex-grow-1"
      >
        <a
          :title="page.pageName"
          class="nav-link primary-colour"
          :class="currentPage === page.pageName ? 'active' : 'white-text font-weight-light'"
          @click="currentPage = page.pageName"
        >
          <mdb-icon :icon="page.icon" />
          <span
            :class="{'d-none' : currentPage != page.pageName}"
            class="d-sm-inline ml-2"
          >{{ page.pageName }}</span>
        </a>
      </li>
    </ul>
    <div v-show="!loading" class="flex-grow-1 myClubSubsection">
      <Payouts
        v-if="currentPage === 'Payouts'"
        class="myClubSubsectionComponent"
        @loading="setLoading($event)"
      />
      <Players
        v-if="currentPage === 'Players'"
        class="myClubSubsectionComponent"
        @loading="setLoading($event)"
      />
      <ClubDetails
        v-if="currentPage === 'Club Details'"
        class="myClubSubsectionComponent"
        style="overflow-x: none;"
        @loading="setLoading($event)"
      />
      <Nominate
        v-if="currentPage === 'Nominate'"
        class="myClubSubsectionComponent"
        @loading="setLoading($event)"
      />
      <Booster
        v-if="currentPage === 'Booster'"
        class="myClubSubsectionComponent"
        @loading="setLoading($event)"
      />
    </div>
  </div>
</template>

<script>
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'
import Payouts from './payouts.vue'
import Players from './players.vue'
import ClubDetails from './clubDetails.vue'
import Nominate from './nominate.vue'
import Booster from './booster/booster.vue'

export default {
  name: 'MyClub',
  components: {
    LeftTopPanelTitle, Payouts, Players, ClubDetails, Nominate, Booster
  },
  data () {
    return {
      currentPage: 'Payouts',
      pages: [
        { pageName: 'Payouts', icon: 'money-bill-wave' },
        { pageName: 'Players', icon: 'users' },
        { pageName: 'Club Details', icon: 'shield-alt' },
        { pageName: 'Booster', icon: 'rocket' },
      ],
      loading: false
    }
  },
  methods: {
    setLoading (boolean) {
      this.loading = boolean
    }
  }
}
</script>

<style>
.myClubSubsection {
  overflow: auto;
  width: 100%;
}
.myClubSubsectionComponent {
  width: 100%;
}
</style>
