<template>
  <div style="width:100%;">
    <LogoUpload
      :club-id="newClub.cid"
      :logo-src="newClub.pic"
      @logoUploaded="getClub()"
    />
    <validation-observer>
      <form
        style="width:100%;overflow-x:hidden;"
        class="mt-5"
        autocomplete="off"
      >
        <div class="md-form">
          <div class="md-form formMarginTop primary-colour" style="font-size: large;margin-left: 31px;padding-bottom: 20px;">
            Main Details
          </div>
          <div class="md-form form-sm formMarginTop">
            <validation-provider
              mode="lazy"
              name="Club Name"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="newClub.cnm"
                type="text"
                auto-complete="nc-cnm"
                label="Club name"
                style="margin-left:33px;max-width:100%;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Address"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="newClub.adr"
                type="text"
                auto-complete="nc-adr"
                label="Address"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Post Code"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="newClub.pce"
                type="text"
                auto-complete="nc-pce"
                label="Post code"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Forename"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="newClub.fnm"
                type="text"
                auto-complete="nc-fnm"
                label="First name"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Surname"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="newClub.lnm"
                type="text"
                auto-complete="nc-lnm"
                label="Last name"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Email"
              rules="required"
            >
              <i class="fas fa-envelope prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="newClub.eml"
                type="email"
                auto-complete="nc-eml"
                label="Main contact email"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Telephone"
              rules="required"
            >
              <i class="fas fa-phone prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="newClub.tel"
                type="text"
                auto-complete="nc-eml"
                label="Main contact telephone"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Local lottery licence number"
              rules="required"
            >
              <i class="fa fa-id-card prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="newClub.lll"
                type="text"
                auto-complete="nc-lll"
                label="Local lottery licence number"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>

          <div
            v-if="newClub.url != '' && newClub.url != null && newClub.url != 'undefined' && newClub.url != undefined"
            class="md-form formMarginTop primary-colour"
            style="margin-top:20px; font-size: large;margin-left: 31px;padding-bottom: 0px;"
          >
            Leaderboard Settings
          </div>
          <div
            v-if="newClub.url != '' && newClub.url != null && newClub.url != 'undefined' && newClub.url != undefined"
            class="md-form form-sm" 
            style="margin-top:35px;"
          >
            <validation-provider
              mode="lazy"
              name="Club Leaderboard Url"
              rules="required"
            >
              <i class="fas fa-link prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="newClub.url"
                type="text"
                auto-complete="nc-url"
                label="Club leaderboard link address"
                style="margin-left:33px;max-width:100%;"
                size="sm"
                :disabled="userData.rle !== 'sup'"
              />
            </validation-provider>
          </div>
          <div
            v-if="newClub.url != '' && newClub.url != null && newClub.url != 'undefined' && newClub.url != undefined"
            class="md-form form-sm"
            style="margin-top:20px;"
          >
            <i class="fa fa-palette sm" style="left:0;margin-top:18px;font-size:1rem;color:gray;" />
            <mdb-input
              v-model="newClub.acc"
              type="text"
              auto-complete="nc-acc"
              disabled
              label="Accent Colour"
              style="margin-left:33px;margin-top: -30px;"
              size="sm"
            />
          </div>
          <div class="md-form formMarginTop primary-colour" style="margin-top:20px; font-size: large;margin-left: 31px;padding-bottom: 0px;">
            Bank Details
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <i class="fa fa-university prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
            <mdb-input
              v-model="newClub.bnk1"
              type="text"
              auto-complete="nc-bnk1"
              label="Bank name"
              style="margin-left:33px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <i class="fa fa-university prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
            <mdb-input
              v-model="newClub.bnk2"
              type="text"
              auto-complete="nc-bnk2"
              label="Bank account sort code"
              style="margin-left:33px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <i class="fa fa-university prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
            <mdb-input
              v-model="newClub.bnk3"
              type="text"
              auto-complete="nc-bnk3"
              label="Bank account number"
              style="margin-left:33px;"
              size="sm"
            />
          </div>
          <div
            class="md-form form-sm"
            style="margin-top:25px;margin-bottom:35px;"
            @click="toggleAgree()"
          >
            <mdb-input
              v-model="agree"
              type="checkbox"
              auto-complete="nc-agr"
              style="margin-left:-20px;"
              size="sm"
            />
            <div style="margin-left: 33px;margin-top: -14px;font-size: small;" class="text-white">
              Are you sure you would like to {{ clubId === '0' ? 'save' : 'change' }} the club details?
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
    <div style="padding-bottom: 60px;" class="d-flex flex-column mr-4 mr-md-5 mobBtn102">
      <mdb-btn
        v-show="!buttonClick"
        :disabled="agree === false || newClub.tik < 1"
        style="width:100px;margin:0 !important;"
        class="mobBtn102 btn primary-btn btn-right btn-radius btn-sm btn-md"
        size="sm"
        @click="addEditClubDetails()"
      >
        SAVE
      </mdb-btn>
      <mdb-btn
        v-show="buttonClick"
        style="width:100px;margin:0 !important;"
        class="mobBtn102 btn primary-btn btn-right btn-radius btn-sm btn-md"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'
import LogoUpload from '@/components/logoUpload.vue'

export default {
  name: 'ClubDetails',
  components: { LogoUpload },
  data () {
    return {
      newClub: {
        cnm: '',
        adr: '',
        pce: '',
        fnm: '',
        lnm: '',
        eml: '',
        tel: '',
        lll: '',
        pic: '',
        cid: 0,
        udd: 1,
        umm: 1,
        uyy: 1980,
        drw: false,
        url: '',
        aff: '',
        ply: 0,
        acc: '#1FFC35',
        bnk1: '',
        bnk2: '',
        bnk3: ''
      },
      buttonClick: false,
      agree: false,
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    clubId () {
      return this.userData.cid
    }
  },
  watch: {
    clubId () {
      if (Number(this.clubId) === 0) {
        this.newClub = {
          cnm: '',
          adr: '',
          pce: '',
          fnm: '',
          lnm: '',
          eml: '',
          tel: '',
          lll: '',
          pic: '',
          cid: 0,
          udd: 1,
          umm: 1,
          uyy: 1980,
          drw: false,
          url: '',
          aff: '',
          ply: 0,
          acc: '#1FFC35',
          bnk1: '',
          bnk2: '',
          bnk3: ''
        }
      }
    }
  },
  mounted () {
    this.getClub()
  },
  methods: {
    toggleAgree () {
      this.agree = !this.agree
    },
    getClub () {
      if (Number(this.clubId) === 0) { return }
      adminService.getClubById(this.clubId, this.userData.access_token).then((res) => {
        this.newClub = { ...this.newClub, ...res.data }
      }).catch(() => {

      })
    },
    addEditClubDetails () {
      this.buttonClick = true
      adminService.addEditClubDetails(this.newClub, this.userData.access_token).then(() => {
        this.agree = false
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'Success', errorMessage: 'Club details updated!' })
        this.buttonClick = false
      }).catch((err) => {
        this.buttonClick = false
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'Error', errorMessage: Object.assign({}, err).response.data.Message })
        this.agree = false
      })
    }
  }
}
</script>

