<template>
  <!-- CALCULATOR -->
  <div class="boost-calculator-container d-flex flex-column primary align-items-center p-0 p-md-3">
    <div class="text-lg primary my-2" style="font-size: clamp(1.2rem, 2.2vw, 1.5rem)">
      BOOST CALCULATOR
    </div>
    <div
      class="d-flex align-items-center"
      style="margin-bottom: -15px">
      <i class="fa fa-clipboard" style="left:0;color:gray;" />
      <mdb-select
        v-model="ticketsSoldPerMonth"
        label="Tickets sold per month"
        style="margin-left:20px;width: 240px"
      />
    </div>
    <div
      class="d-flex align-items-center"
      style="margin-bottom: -15px">
      <i class="fas fa-rocket" style="left:0;color:gray;" />
      <mdb-input
        v-model="calculatorBoostAmount"
        type="number"
        auto-complete="nc-cnm"
        label="Boost Amount Per Entry"
        style="margin-left:20px;width: 240px"
        size="sm"
      />
    </div>
    <div class="d-flex align-items-center">
      <i class="fas fa-user" style="left:0;color:gray;" />
      <mdb-select
        v-model="calculatorShare"
        label="Club Leaderboard Share"
        style="margin-left:20px;width: 240px"
      />
    </div>
    <div class="d-flex align-items-center calculator-payout-row">
      <div class="mr-3">
        EXTRA CLUB PAYOUT
      </div>
      <div class="calculator-result-box">
        <div>£{{ calculatorPayoutAmount }}</div>
        <span>PER MONTH</span>
      </div>
    </div>
    <div class="d-flex align-items-center calculator-payout-row mt-3">
      <div class="mr-3">
        LEADERBOARD PRIZE
      </div>
      <div class="calculator-result-box">
        <div>£{{ calculatorLeaderboardAmount }}</div>
        <span>PER MONTH</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BoosterCalculator',
  data () {
    return {
      ticketsSoldPerMonth: [
        { value: 50, text: '50', selected: true },
        { value: 100, text: '100' },
        { value: 200, text: '200' },
        { value: 500, text: '500' },
      ],
      calculatorBoostAmount: 5,
      calculatorShare: [
        { value: 50, text: 'Club ( 50% ) / LeaderBoard (50% )', selected: true },
        { value: 60, text: 'Club ( 60% ) / LeaderBoard (40% )' },
        { value: 40, text: 'Club ( 40% ) / LeaderBoard (60% )' },
        { value: 75, text: 'Club ( 75% ) / LeaderBoard (25% )' },
        { value: 25, text: 'Club ( 25% ) / LeaderBoard (75% )' },
        { value: 70, text: 'Club ( 70% ) / LeaderBoard (30% )' },
        { value: 30, text: 'Club ( 30% ) / LeaderBoard (70% )' },
        { value: 80, text: 'Club ( 80% ) / LeaderBoard (20% )' },
        { value: 20, text: 'Club ( 20% ) / LeaderBoard (80% )' },
        { value: 90, text: 'Club ( 90% ) / LeaderBoard (10% )' },
        { value: 10, text: 'Club ( 10% ) / LeaderBoard (90% )' },
        { value: 0, text: 'LeaderBoard (100% )' }
      ]
    }
  },
  computed: {
    selectedTicketsPerMonth () {
      return this.ticketsSoldPerMonth.find(s => s.selected == true).value
    },
    selectedCalculatorShare () {
      return this.calculatorShare.find(s => s.selected == true).value
    },
    totalExtraMoneyFromBoost () { return this.calculatorBoostAmount * this.selectedTicketsPerMonth },
    totalCalculatorAmount () {
      return this.calculatorBoostAmount * this.selectedTicketsPerMonth
    },
    calculatorPayoutAmount () {
      return (this.totalExtraMoneyFromBoost * ((100 - this.selectedCalculatorShare) / 100)).toFixed(2)
    },
    calculatorLeaderboardAmount () {
      return (this.totalExtraMoneyFromBoost * (this.selectedCalculatorShare / 100)).toFixed(2)
    }
  }
}
</script>

<style>
.boost-calculator-container {
  border: 3px solid var(--pr-color);
  border-radius: 5px;
  width: 400px;
  max-width: 100%;
}
@media (max-width: 576px) {
  .boost-calculator-container {
    width: 300px;
  }
}
.calculator-result-box {
  border-radius: 5px;
  border: solid var(--pr-color) 2px;;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.calculator-result-box div {
  font-size: clamp(1.3rem, 2vw, 2rem);
  color: var(--pr-color);
}
.calculator-result-box span {
  color:white;
}
.calculator-payout-row {
  height: 100px;
}
</style>
